function toggleMegaMenu() {
	
	if (!document.querySelector("#hamburger")) {
		return
	}

	document.querySelector("#hamburger").addEventListener("click", () => {
		document.querySelector("#mega-menu").classList.toggle("active")
	});
	document.querySelector(".hide-menu").addEventListener("click", () => {
		document.querySelector("#mega-menu").classList.toggle("active")
	});
	
}

export { toggleMegaMenu };