// Debounce limits how often the scroll function fires
function debounce(method, delay) {
	clearTimeout(method._tId);
	method._tId = setTimeout(function() {
		method();
	}, delay);
}

// This function will run a throttled script every 300 ms
function scrollFunction(){ 
	//console.log('checkHeader for shadow class');

	// Detect scroll position
	let scrollPosition = Math.round(window.scrollY);

	// If we've scrolled 300px, add "sticky" class to the header
	if (scrollPosition > 300){
		document.querySelector('.site-header').classList.add('header-with-shadow');
	}
	// If not, remove "sticky" class from header
	else {
		document.querySelector('.site-header').classList.remove('header-with-shadow');
	}
};

function initScrollForHeader(){
	// Run the checkHeader function every time you scroll
	window.onscroll = function() {
		debounce(scrollFunction, 300);
	};
}

export { initScrollForHeader };

