function hideAllMenus() {
	const dropdowns = document.getElementsByClassName("dropdown");
	for (let i = 0; i < dropdowns.length; i++) {
		if (!dropdowns[i].classList.contains("hidden")) {
			dropdowns[i].classList.add("hidden");
		}
	}
}

function navDropdowns() {
	const dropdownLinkList = document.getElementsByClassName("nav-dropdown");
	for (let i = 0; i < dropdownLinkList.length; i++) {
		const dropDownLink = dropdownLinkList[i].querySelector(".dropdown-link");
	
		dropDownLink && dropDownLink.addEventListener("click", (event) => {
			event.preventDefault()
			hideAllMenus();
			event.target.parentNode.querySelector(".dropdown").classList.toggle("hidden");
		})
	}
}

export { navDropdowns };