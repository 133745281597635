import Glide from '@glidejs/glide';

/* OLD Flickity Slider functions

import Flickity from "flickity";
import "flickity-as-nav-for";

const defaultSliderConfig = {
	contain: true,
	pageDots: false,
	arrowShape: {
		x0: 10,
		x1: 60,
		y1: 50,
		x2: 65,
		y2: 50,
		x3: 15
	},
	// wrapAround: true,
	imagesLoaded: true,
};

function createSlider(el, options) {
	new Flickity(el, options);
}

function createNavSlider(el, main) {
	createSlider(el, {
		...defaultSliderConfig,
		asNavFor: main.element,
	});
}

function createMainSlider(el) {
	createSlider(el, {
		...defaultSliderConfig,
		lazyLoad: true,
	});
}

*/

let stopMedia = function ( element ) {
  
  /* This function removes dynamically loaded content from any open modals thus stopping any media thats playing */
  let dataHolderToClear = element.querySelector('.dynamic-modal-data-holder');
  
  if (dataHolderToClear) {
	dataHolderToClear.innerHTML = '';
  }
  
};


function attachModals() {

	document.querySelectorAll("[data-popup-trigger]").forEach(

		el => el.addEventListener("click", function(e) {

			if (el.hasAttribute("data-dynamically-load-content")) {

				let targetData = el.getAttribute('data-dynamically-load-content');

				const targetModal = document.querySelector(`[data-popup-modal="${el.dataset.popupTrigger}"]`);

				loadDynamicModalData(targetData, targetModal);

			} else {
				console.log('regular modal');
			}

			if (e.target.tagName === "I" || e.target.tagName === "A") {
				return
			}

			createModal(el)

		})

	)

}


function loadDynamicModalData(dynamicData, dynamicModal) {

	let dataHolder = dynamicModal.querySelector('.dynamic-modal-data-holder');
	let modalPreloader = dynamicModal.querySelector('.modal-preloader');

	modalPreloader.classList.remove("modal-preloader-hidden");

	fetch(dynamicData).then(function(response) {
		// The API call was successful!
		return response.text();
	}).then(function(html) {

		// Hide the preloader 
		modalPreloader.classList.add("modal-preloader-hidden");

		// Use appendChild to only insert the content of the dynamic-data-content div 
		// Convert the HTML string into a document object
		var parser = new DOMParser();
		var doc = parser.parseFromString(html, 'text/html');
		var parsedDynamicData = doc.getElementById("dynamic-data-content");

		dataHolder.appendChild(parsedDynamicData);

		dataHolder.classList.add("opacity-100");


		/* If there is a dynamic gallery */
		document.querySelectorAll('.dynamic-modal-gallery').forEach(function(dynamicGallery) {
			/*dynamicGallery.createGlideSlider(el.querySelector(".glide"))*/
			console.log('initiate the gallery!');

			new Glide(dynamicGallery, {
				type: 'carousel',
				startAt: 0,
				perView: 1
			}).mount();

		});


		let ModalExtendedCopyToggles = document.querySelectorAll('.modal-extended-copy-toggle');

		ModalExtendedCopyToggles.forEach(ModalExtendedCopyToggle => {

			ModalExtendedCopyToggle.addEventListener('click', (event) => {

				event.preventDefault();

				let toggleTarget = ModalExtendedCopyToggle.getAttribute('data-target');

				/* toggle the class 'show' on all elements with the class of the data target */

				let targetExpandedModalCopy = document.querySelectorAll(toggleTarget);

				targetExpandedModalCopy.forEach(function(el) {
					el.classList.toggle("show");
				});

				/* Toggle the chevron */

				const thisToggle = event.currentTarget;
				thisToggle.querySelectorAll(".las").forEach(el => el.classList.toggle("hidden"));

			});

		});



	}).catch(function(err) {
		// There was an error
		console.warn('Something went wrong.', err);
	});


}

function removeOverlay() {
	(document.getElementById("modal-overlay") || { remove: () => "" }).remove();
	// disabled as its causing the page to jump around
	// allowBodyScroll();
}

function createModal(el) {
	const popupModal = document.querySelector(`[data-popup-modal="${el.dataset.popupTrigger}"]`);

	const createOverlay = () => {
		const overlay = document.createElement("div");
		overlay.classList = "fixed top-0 left-0 bg-gray-700 z-30 w-full h-full opacity-95";
		overlay.setAttribute("id", "modal-overlay");
		return overlay;
	}
	// disabled as its causing the page to jump around
	// preventBodyScroll();

	popupModal.classList.remove("hidden");

	popupModal.querySelectorAll("[popup-close]").forEach(
		close => close.addEventListener("click", (e) => {
			if (e.target.hasAttribute("popup-close")) {
				popupModal.classList.add("hidden");
				removeOverlay();

				let currentModal = popupModal;
				stopMedia(currentModal);
			}
		})
	);

	// close modals with esc key
	document.addEventListener('keydown', (event) => {
		if (event.key === 'Escape') {
			popupModal.classList.add("hidden");
			removeOverlay();

			let currentModal = popupModal;
			stopMedia(currentModal);
		}
	});

	document.body.appendChild(createOverlay());

	hideLoader();
	
	/* OLD Flickity Galleries
	if (popupModal.querySelector(".nav-slider")) {
		createNavSlider(
			popupModal.querySelector(".nav-slider"),
			createMainSlider(popupModal.querySelector(".main-slider"))
		);
	}
	*/
}

function hideLoader() {
	const mainImages = document.querySelectorAll(".main-slider img")
	mainImages.forEach(image => image.addEventListener("load", () => {
		image.nextElementSibling.classList.add("hidden")
		image.nextElementSibling.classList.remove("min-h-sm");
		image.nextElementSibling.querySelector(".lds-heart div").classList.add("hidden");
	}));
}


export { attachModals };