function preventBodyScroll() {

	const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');

	const pageBody = document.body;

	document.querySelector("body").classList.add("prevent-body-scroll");

	pageBody.style.top = `-${scrollY}`;

}

function allowBodyScroll() {

	const pageBody = document.body;

	const scrollY = pageBody.style.top;

	document.querySelector("body").classList.remove("prevent-body-scroll");

	pageBody.style.top = '';

	window.scrollTo(0, parseInt(scrollY || '0') * -1);

}

function displayCountry() {
	
	const aocPopup = document.getElementById("acknowledgement-of-country");
	const canUserStorage = window.localStorage;
	
	if (!canUserStorage.getItem("hide-acknowledgement-country")) {
		// AOC hasn't been actioned in this session, hide the AOC and lock the body scroll so the user can't scroll the main page under the modal
		aocPopup.classList.remove("hidden");
		preventBodyScroll();
	} else {
		// AOC has been actioned in this session so lets keep it hidden and let the body scroll
		allowBodyScroll();
	}
	
	document.getElementById("hide-acknowledgement-country").addEventListener("click", () => {
		canUserStorage.setItem("hide-acknowledgement-country", true);
		aocPopup.classList.add("hidden");
		allowBodyScroll();
	})
	
}

export { displayCountry };