function expandContent() {

	const collapse = selector => document
		.querySelectorAll(selector)
		.forEach(target => target.classList["toggle"]("show"));

	document
		.querySelectorAll('[data-toggle="expand"]')
		.forEach(node => node.addEventListener("click", (e) => {
			e.preventDefault()
			const el = e.currentTarget;
			el.querySelectorAll(".las").forEach(el => el.classList.toggle("hidden"))
			collapse(el.getAttribute("data-target"))
		}));

}

export { expandContent };