import Glide from '@glidejs/glide';

function createGlideSlider(el) {
	new Glide(el, {
		type: 'carousel',
		startAt: 0,
		perView: 1
	}).mount()
}

function initGlideSliders(){
	
	document.querySelectorAll("[data-glide-slider]").forEach(
		el => createGlideSlider(el.querySelector(".glide"))
	)
	
}

export { initGlideSliders };