import '@/css/app.pcss';

import Glide from '@glidejs/glide';

import "lazysizes";

import {
  fadeCanSiteIn,
} from "./modules/fade-site-in.js";

import {
  displayCountry,
} from "./modules/aoc.js";

import {
  navDropdowns,
} from "./modules/nav-dropdowns.js";

import {
  toggleMegaMenu,
} from "./modules/toggle-mega-menu.js";

import {
  loadArtGallery,
  loadEventsAndResourcesGallery,
} from "./modules/grid-galleries.js";

import {
  attachModals,
} from "./modules/modals.js";

import {
  initGlideSliders,
} from "./modules/glide-sliders.js";

/* Disabled these have been migrated to GlideJS 
import {
  projectSlider,
  singleEntryArtsGallerySlider,
} from "./modules/flickity-sliders.js";
*/

import {
  expandContent,
} from "./modules/expand-content.js";

import {
  initScrollForHeader,
} from "./modules/header-scrollpoint.js";

/*
import {
  filterProducts,
  searchProducts,
} from "./modules/products.js";
*/


// For the on Doc ready: Define a convenience method and use it
let ready = (callback) => {
  if (document.readyState != "loading") callback();
  else document.addEventListener("DOMContentLoaded", callback);
}

ready(() => {
  /* Do things after DOM has fully loaded */
  console.log('Doc is ready!');

  fadeCanSiteIn();
  
  displayCountry();
  
  navDropdowns();
  
  toggleMegaMenu();
  
  initGlideSliders();
  
  loadArtGallery();
  
  loadEventsAndResourcesGallery();
  
  attachModals();
  
  expandContent();
  
  initScrollForHeader();
  
}); // END DOC READY