import Isotope from "isotope-layout";
import imagesLoaded from "imagesloaded";

function toggleClearFilters(color) {
	const clearFilters = document.querySelector("[data-clear-filters]");

	if (color === "dark") {
		clearFilters.classList.add("text-gray-700");
		clearFilters.classList.remove("text-gray-500");
	} else if (color === "light") {
		clearFilters.classList.add("text-gray-500");
		clearFilters.classList.remove("text-gray-700");
	}
}

function createFilterButtons(filter, iso) {
	const close = document.createElement("i")
	const button = document.createElement("button");
	button.setAttribute("data-filter-group", filter.group);
	button.setAttribute("data-filter", filter.value);
	button.innerText = filter.text
	button.classList = "tag text-white rounded-full px-8 py-1 relative";
	button.classList.add(filter.active ? "bg-gray-700" : "bg-gray-500");
	button.addEventListener("click", () => setFilter(filter, iso))

	close.classList = "rounded-full p-1 bg-white text-gray-700 absolute top-0 right-0 transform -translate-y-1 las la-times la-sm shadow-lg"
	filter.active && button.appendChild(close);

	return button;
}

function setFilter(filter, iso) {
	const dropdown = document.querySelector(`[data-group-filter] [data-filter-group="${filter.group}"]`);
	dropdown.value = filter.active ? "" : filter.value;
	renderGalleryFilters(iso)
}

function renderGalleryFilters(iso) {
	return renderFilters(iso, galleryFilters());
}

function renderFilters(iso, filters) {
	const taggedFilters = document.querySelector("[data-filters-tag-group]") || {};
	const appendChild = (child) => taggedFilters.appendChild(child)
	const buttons = (l) => l.map((v) => createFilterButtons(v, iso));
	const active = filters.filter((filter) => filter.active);
	const activeNotFeatured = filters.filter((filter) => filter.active && !filter.featured);
	const featured = filters.filter((filter) => filter.featured);

	if (active.length !== 0) {
		toggleClearFilters("dark");
	} else {
		toggleClearFilters("light");
	}

	taggedFilters.innerHTML = "";
	buttons(activeNotFeatured).map(appendChild);
	buttons(featured).map(appendChild);

	iso.arrange({
		filter: active.flatMap(f => f.value).join("")
	});

	displayNoMatchText(iso);
}

function clearDataSearch(){
  let dataSearchFields = document.querySelectorAll("[data-search]");
  dataSearchFields.forEach((el) => el.value = '');
}

function clearGalleryFilters(iso) {
	galleryDropdowns().forEach((el) => el.selectedIndex = 0);
	clearDataSearch();
	renderGalleryFilters(iso);
	
	
}

function clearMapFilters(iso) {
	galleryDropdowns().forEach((el) => el.selectedIndex = 0);

	renderGalleryFilters(iso);
}

function debounce(fn, threshold) {
	let timeout;
	threshold = threshold || 100;
	return function debounced() {
		clearTimeout(timeout);
		const args = arguments;
		const _this = this;

		function delayed() {
			fn.apply(_this, args);
		}

		timeout = setTimeout(delayed, threshold);
	};
}



function displayNoMatchText(iso) {
	const match = document.querySelector("#no-match")

	if (!match) {
		return
	}

	iso.filteredItems.length === 0 ? match.classList.remove("hidden") : match.classList.add("hidden")
}

function galleryDropdowns() {
	return document.querySelectorAll("#gallery [data-group-filter] select");
}

function galleryFilters() {
	return groupedFilters(galleryDropdowns());
}

function groupedFilters(dropdowns) {
	const optionArray = (el) => Array.from(el.querySelectorAll("option").values()).filter(el => el.getAttribute("disabled") === null);

	return Array.from(dropdowns.values()).flatMap(dropdown => {
		return optionArray(dropdown).flatMap((option) => ({
			group: dropdown.getAttribute("data-filter-group"),
			value: option.value,
			text: option.text,
			active: option.selected,
			featured: option.getAttribute("data-featured") === "",
		}));
	});
}

function loadArtGallery() {
	let qsRegex;
	const galleryGrid = document.querySelector(".gallery-grid");

	if (!galleryGrid) {
		return;
	}

	const iso = new Isotope(galleryGrid, {
		itemSelector: ".grid-item",
		percentPosition: true,
		masonry: {
			// use element for option
			columnWidth: '.grid-sizer'
		},
		filter: function (itemElem) {
			return qsRegex ? itemElem.classList.value.match(qsRegex) : true;
		}
	})

	renderGalleryFilters(iso);

	galleryDropdowns().forEach(
		el => el.addEventListener("change", () => renderGalleryFilters(iso))
	);

	document.querySelectorAll("[data-clear-filters]").forEach(
		el => el.addEventListener("click", () => clearGalleryFilters(iso))
	);

	document.querySelector('[data-search="quick"]')
		.addEventListener("keyup", (debounce(function (e) {
			iso.arrange({
				filter: function (itemElem) {
					return itemElem.classList.value.match(new RegExp(e.target.value.replace(" ", "-"), "gi"));
				}
			})
			displayNoMatchText(iso);
		}, 200)));

	imagesLoaded(document.querySelector(".gallery-grid")).on("progress", () => {
		iso.layout();
	});

	document.querySelector(".gallery-grid").classList.remove("hidden");
	document.querySelector(".lds-heart").parentNode.classList.add("hidden");

	iso.layout();
	
	iso.on( 'arrangeComplete', function() {
	  /* When the initial layout is complete we then run the intersection observer functions to load images when they come into view (otherwise the document thinks the images are in view straightaway - something to do with the way isotope effects the layout */
	  artworkCoverImages.forEach((el) => observer.observe(el));
	} )
	
	/* First we get all the non-loaded image elements */
	var artworkCoverImages = [].slice.call(document.querySelectorAll(".lazy-loaded-image.lazy"));
	
	const observerOptions = {
	  root: null,
	  rootMargin: '0px',
	  threshold: 0.1,
	};
	
	function observerCallback(entries) {
	  entries.forEach((entry) => {
		if (entry.isIntersecting) {
		  let lazyImage = entry.target;
		  lazyImage.src = lazyImage.dataset.src;
		  lazyImage.classList.remove("lazy");
		  /*observerCallback.unobserve(lazyImage);*/
		 }
	  });
	}
	
	const observer = new IntersectionObserver(observerCallback, observerOptions);
	
}


function loadEventsAndResourcesGallery() {

	let qsRegex;
	const eventsAndResourcesGrid = document.querySelector(".events-and-resources-grid");

	if (!eventsAndResourcesGrid) {
		return;
	}

	const iso = new Isotope(eventsAndResourcesGrid, {
		itemSelector: ".sector-development-tile",
		layoutMode: 'fitRows',
		percentPosition: true,
		masonry: {
			// use element for option
			columnWidth: '.grid-sizer'
		},
		filter: function(itemElem) {
			return qsRegex ? itemElem.classList.value.match(qsRegex) : true;
		}
	})

	renderGalleryFilters(iso);

	galleryDropdowns().forEach(
		el => el.addEventListener("change", () => renderGalleryFilters(iso))
	);

	document.querySelectorAll("[data-clear-filters]").forEach(
		el => el.addEventListener("click", () => clearGalleryFilters(iso))
	);

	document.querySelector('[data-search="quick"]')
		.addEventListener("keyup", (debounce(function(e) {
			iso.arrange({
				filter: function(itemElem) {
					return itemElem.classList.value.match(new RegExp(e.target.value.replace(" ", "-"), "gi"));
				}
			})
			displayNoMatchText(iso);
		}, 200)));

	imagesLoaded(document.querySelector(".events-and-resources-grid")).on("progress", () => {
		iso.layout();
	});


	document.querySelector(".lds-heart").parentNode.classList.add("hidden");

	iso.layout();


}

export { loadArtGallery, loadEventsAndResourcesGallery };
